import React from "react"
import Helmet from "react-helmet"
import Navigation from "../components/Navigation"
import Logo from "../components/Logo"

const Header = props => (
  <div>
    <div className="header">
      <Logo />
      <Navigation />
      <Helmet defaultTitle="Cara Margot" titleTemplate="Cara Margot | %s" />
    </div>
    <hr />
  </div>
)

export default Header
