import React from "react"
import { Link } from "gatsby"
import LogoPic from "../images/logo2.png"

const Logo = props => (
  <Link to="/">
    <img src={LogoPic} alt="Margot" className="logo" />
  </Link>
)

export default Logo
