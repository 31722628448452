import React from "react"
import Header from "../components/Header"
import "../styles/main.scss"

class Wrapper extends React.Component {
  render() {
    return (
      <div className="container">
        <Header />
        {this.props.children}
      </div>
    )
  }
}

export default Wrapper
