import React from "react"
import { Link } from "gatsby"
import Logo from "./Logo"
const ListLink = props => (
  <li className="nav-li">
    <Link className="nav-link" to={props.to}>
      {props.children}
    </Link>
  </li>
)

class Navigation extends React.Component {
  render() {
    return (
      <div className="navigation">
        <ul className="nav-ul">
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/contact">Contact</ListLink>
        </ul>
        <hr />
      </div>
    )
  }
}

export default Navigation
